import enLocale from 'element-ui/lib/locale/lang/en'   //引入element语言包
// import videoLocale from 'vue-core-video-player/docs/en'   //引入element语言包
// en.js
export default {
    bottom:{
        text:'Powered by Quantum Space Studio Limited'
    },
    dashboard: {
        btn: {
            play: "Play",
            pause: "Pause",
            fullscreen: "Full Screen",
            exitFullscreen: "Exit Full Screen",
            mute: "Mute",
            unmute: "Unmute",
            back: "Back",
            pip: "Picture-in-Picture"
        },
        settings: {
            autoplay: "Autoplay",
            loop: "Loop",
            speed: "Speed",
            resolution: "Resolution"
        }
    },
    layers: {
        error: {
            2404: "Video Source Not Found",
            2502: "Media Network Error",
            2503: "Video Cannot DECODE",
            2504: "Video Cannot Play!",
            title: "(O_O)?  Error!"
        },
        loading: {
            msg: "Loading ..."
        }
    },
    main: {
        message: "message",
        display: "display"
    },
    header: {
        HOME: 'HOME',
        WORK: 'WORK',
        ABOUT: 'ABOUT',
    },
    workBtn:{
        ALL:'ALL',
        RESIDENTIAL:'RESIDENTIAL',
        CULTURE:'CULTURE',
        COMMERCIAL:'COMMERCIAL',
        ANIMATION:'ANIMATION',
        VIRTUAL:'VIRTUAL TOUR 360',
        LOADMORE:'LOAD MORE',
        NOMORE:'NO MORE'
    },
    about:{
        title:'What a good software to help you complete more work !',
        aboutUs:'ABOUT US',
        intro:`Creativity is just connecting things. When you ask creative people how they did something, hey feel a little guilty because they didn't really do it, they just saw something. What wewanna do is "connecting" your idea with us and generate something wonderful. Foundedin Oct. 2015, Quantum Space Studio aims for best communication and visualization service. Most of our artists have being in this industry for years, experienced in renderings, animation.`,
        ourClients:'OUR CLIENTS',
        contactUs:'CONTACT US'
    },
    ...enLocale,
    // ...videoLocale
}