var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(_vm.bgimgs))?_c('div',{staticClass:"img-box video-box",style:({ backgroundImage: 'url(' + _vm.bgimgs + ')' })}):_c('video',{staticClass:"video-box",attrs:{"src":_vm.bgimgs,"muted":"","autoplay":_vm.isMobile ? false : true},domProps:{"muted":true}}),(_vm.carouselList.length > 0)?_c('el-carousel',{staticClass:"carousel-work",staticStyle:{"margin-top":"100px"},attrs:{"interval":100000,"trigger":"click","height":"650px","arrow":_vm.carouselList.length > 1 ? 'hover' : 'never'},on:{"change":_vm.carouselChange}},_vm._l((_vm.carouselList),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"carousel-main"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.screenWidth > 800),expression:"screenWidth > 800"}],staticClass:"carousel-main-left",style:({ width: _vm.screenWidth > 1100 ? '400px' : '200px' })},[_c('h1',[_vm._v("SCIENCE")]),_c('h1',[_vm._v("AND")]),_c('h1',[_vm._v("DESIGN")])]),_c('div',{staticClass:"carousel-main-right",style:({ paddingRight: _vm.screenWidth > 1000 ? '60px' : 0 })},[(_vm.carouselIndex == index)?_c('vue-core-video-player',{attrs:{"src":item,"controls":"auto","loop":false,"preload":"nona"}}):_vm._e()],1)])])}),1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dialogTableVisible && !_vm.vrVisiled),expression:"!dialogTableVisible && !vrVisiled"}],staticClass:"filter-list"},[_c('el-dropdown',{staticClass:"nav-menu",attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-s-unfold"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l(([
          'ALL',
          'COMMERCIAL',
          'CULTURE',
          'RESIDENTIAL',
          'ANIMATION',
          'VIRTUAL' ]),function(item,index){return _c('el-dropdown-item',{key:index,attrs:{"command":item}},[_vm._v(_vm._s(_vm.$t('workBtn.' + item)))])}),1)],1),_c('el-tabs',{staticClass:"filter-list-tabs",class:_vm.isNight ? 'night' : 'daytime',attrs:{"before-leave":_vm.beforeLeave,"stretch":""},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l(([
          'ALL',
          'COMMERCIAL',
          'CULTURE',
          'RESIDENTIAL',
          'ANIMATION',
          'VIRTUAL' ]),function(item,index){return _c('el-tab-pane',{key:index,style:(_vm.mainStyle),attrs:{"label":_vm.$t('workBtn.' + item),"name":item}})}),1)],1),_c('transition-group',{ref:"waterfallList",staticClass:"v-waterfall-content",style:({
      height: _vm.boxHeight + 'px',
      background: _vm.isNight ? '#000' : '#fff',
      minHeight:'200px',
      color: _vm.isNight ? '#fff' : '#000',
      position: 'relative'
    }),attrs:{"name":"list","tag":"div","id":"v-waterfall"}},_vm._l((_vm.waterfallList),function(img,index){return _c('div',{key:index,staticClass:"v-waterfall-item",style:({
        top: img.top + 'px',
        left: img.left + 'px',
        width: _vm.waterfallImgWidth + 'px',
        height: img.height,
        transition: 'all .3s linear',
      })},[(img.src)?_c('el-image',{staticClass:"v-waterfall-item-img",attrs:{"src":img.src,"fit":"fit"},on:{"click":function($event){return _vm.playVideo(img, index, img.isImg)}}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v(" "+_vm._s(_vm.$t("layers.loading.msg"))+" ")])]):_vm._e()],1)}),0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.btnDisabled),expression:"btnDisabled"}],style:({
      background: _vm.isNight ? '#000' : '#fff',
      color: _vm.isNight ? '#fff' : '#000',
      paddingTop: '100px',
    })}),(_vm.dialogTableVisible)?_c('div',{staticClass:"img-background el-image-viewer__wrapper",staticStyle:{"z-index":"2008","background":"#fff","align-items":"center","display":"flex","justify-content":"center"},attrs:{"fullscreen":""},on:{"click":_vm.closeBig}},[_c('div',{staticStyle:{"margin":"40px"}},[(_vm.currentIdndex)?_c('span',{staticClass:"el-image-viewer__btn el-image-viewer__prev",on:{"click":function($event){$event.stopPropagation();return _vm.backImg($event)}}},[_c('i',{staticClass:"el-icon-arrow-left"})]):_vm._e(),(_vm.currentIdndex < _vm.waterfallList.length - 1)?_c('span',{staticClass:"el-image-viewer__btn el-image-viewer__next",on:{"click":function($event){$event.stopPropagation();return _vm.nextImg($event)}}},[_c('i',{staticClass:"el-icon-arrow-right"})]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"center"},on:{"click":_vm.closeBig}},[(_vm.waterfallList[_vm.currentIdndex].isImg && _vm.waterfallList[_vm.currentIdndex].bigSrc.indexOf('.mp4')<0)?_c('el-image',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.bigImgLoading),expression:"bigImgLoading"}],staticClass:"modal-img",style:({objectFit: 'contain',
            width: _vm.isMobile ? '100vw' : _vm.ratio ? 'calc(100vw - 80px)' : 'auto',
            maxWidth: _vm.isMobile ? '100vw' : 'calc(100vw - 80px)',
            height: _vm.isMobile ? 'auto' : _vm.ratio ? 'auto' : 'calc(100vh - 80px)',
            maxHeight: _vm.isMobile ? 'auto' : 'calc(100vh - 80px)'}),attrs:{"src":("data:image/png;base64," + _vm.base64),"fit":"contain","lazy":""},on:{"click":function($event){$event.stopPropagation();return _vm.imgClick($event)}}}):_c('video-player',{ref:"videoPlayer",style:({objectFit: 'contain',
                width: _vm.isMobile ? '100vw' : 'calc(100vw)',
                maxWidth: _vm.isMobile ? '100vw' : '1920px'}),attrs:{"playsinline":true,"options":_vm.playerOptions},on:{"play":function($event){return _vm.onPlayerPlay($event)},"pause":function($event){return _vm.onPlayerPause($event)},"ended":function($event){return _vm.onPlayerEnded($event)},"waiting":function($event){return _vm.onPlayerWaiting($event)},"playing":function($event){return _vm.onPlayerPlaying($event)},"loadeddata":function($event){return _vm.onPlayerLoadeddata($event)},"timeupdate":function($event){return _vm.onPlayerTimeupdate($event)},"canplay":function($event){return _vm.onPlayerCanplay($event)},"canplaythrough":function($event){return _vm.onPlayerCanplaythrough($event)},"statechanged":function($event){return _vm.playerStateChanged($event)},"ready":_vm.playerReadied}}),_c('div',{staticClass:"v-waterfall-item-title"},[_c('p',{staticStyle:{"margin-right":"30px"},domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.subTitle)}})])],1)])]):_vm._e(),_c('el-backtop',{attrs:{"bottom":10,"right":10}},[_c('i',{staticClass:"el-icon-arrow-up",staticStyle:{"color":"rgb(102, 102, 102)"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vrVisiled),expression:"vrVisiled"}],staticClass:"img-background el-image-viewer__wrapper",staticStyle:{"background-color":"#fff","z-index":"10000"},on:{"click":_vm.closeBig}},[_c('div',{style:({objectFit: 'contain',
            width: _vm.isMobile ? '100vw' : _vm.ratio ? 'calc(100vw - 150px)' : 'auto',
            maxWidth: _vm.isMobile ? '100vw' : 'calc(100vw - 150px)',
            height: _vm.isMobile ?'calc(100vh - 140px)': 'calc(100vh - 40px)' ,
            maxHeight: _vm.isMobile ? 'calc(100vh - 140px)': 'calc(100vh - 40px)',
            overflow: 'hidden',
            margin:_vm.isMobile ? '70px auto': '40px auto'
            })},[_c('div',{staticClass:"el-image-viewer__mask",staticStyle:{"opacity":"1","background":"none","filter":"blur(10px)","backdrop-filter":"blur(12px)"},on:{"click":function($event){$event.stopPropagation();return _vm.closeBig($event)}}}),(_vm.currentIdndex)?_c('span',{staticClass:"el-image-viewer__btn el-image-viewer__prev",staticStyle:{"z-index":"10001"},on:{"click":function($event){$event.stopPropagation();return _vm.backImg($event)}}},[_c('i',{staticClass:"el-icon-arrow-left"})]):_vm._e(),(_vm.currentIdndex < _vm.waterfallList.length - 1)?_c('span',{staticClass:"el-image-viewer__btn el-image-viewer__next",staticStyle:{"z-index":"10001"},on:{"click":function($event){$event.stopPropagation();return _vm.nextImg($event)}}},[_c('i',{staticClass:"el-icon-arrow-right"})]):_vm._e(),_vm._m(0),_c('div',{staticClass:"v-waterfall-item-title"},[_c('p',{staticStyle:{"margin-right":"30px"},domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.subTitle)}})])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","width":"inherit","justify-content":"center"}},[_c('div',{attrs:{"id":"container"}})])}]

export { render, staticRenderFns }