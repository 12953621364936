import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
//import VueI18n from 'vue-i18n'
//import vueMiniPlayer from 'vue-mini-player'
// import 'vue-mini-player/lib/vue-mini-player.css'
//import locale from 'element-ui/lib/locale/lang/en'
//import i18n from './locales/index.js'; 
import i18n from './i18n/index'
import en from './i18n/lang/en'
// import zn from './i18n/lang/zn'
import axios from 'axios'
Vue.prototype.$axios = axios
import VueCoreVideoPlayer from 'vue-core-video-player'
let lang = en 
// if(localStorage.getItem('language')){
//   lang = localStorage.getItem('language') == 'zn'? zn:en
// }else{
  lang = en
// }
Vue.use(VueCoreVideoPlayer, {
  lang: lang
})
Vue.use(ElementUI)

//Vue.use(vueMiniPlayer)
Vue.config.productionTip = false
// Vue.use();
new Vue({i18n,
  render: h => h(App),
}).$mount('#app')
