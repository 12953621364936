<template>
  <el-container>
    <el-header>
      <div class="nav-top">
        <div class="nav-top-img"><a href='/' style="display: inline-block"><img class="nav-top-logo" src="../assets/img/nav-logo.png" alt=""></a></div>
        <div class="nav-top-right">
          <el-dropdown class='nav-menu' @command="handleBtn" trigger='click'>
            <span class="el-dropdown-link">
              <i class="el-icon-menu"></i>
            </span>
            <el-dropdown-menu slot="dropdown" >
              <el-dropdown-item v-for="(btn,index) in btns" :key="index" :command="btn.value">{{btn.lable}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="nav-btn">
            <el-button :style="btnStyle(btn.value)" class='nav-menu-btn' @click="handleBtn(btn.value)" type="text" v-for="(btn,index) in btns" :key="index">
              {{btn.lable}}
            </el-button>
          </div>
        </div>
    </div>
    </el-header>
    <el-main v-if="componentVisiled">
        <keep-alive include="WORK">
          <component ref="component" :is="componentId"/>
        </keep-alive>
    </el-main>
    <el-footer v-if="componentVisiled && componentId != 'HOME'" :style="{background:isNight?'#000':'#fff',color:isNight?'#fff':'rgb(102, 102, 102)'}"  class="footer">
      <div class="footer-left">
      <a style="font-weight: 400">{{$t('bottom.text')}}</a>
      </div>
      <div class="footer-right">
        <el-button type="text" v-for="(item, index) in footerIcons" :key="index">
          <a :href="$i18n.locale == 'zn' ? getIconUrl(item.websiteContentEn) : getIconUrl(item.websiteContent)" target="_blank"><img style="height: 20px;vertical-align: middle;" :src="item.imgSrc" alt=""/></a>
        </el-button>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import HOME from "./home.vue";
import WORK from "./work.vue";
import ABOUT from "./about.vue";
// import LangSelect from '@/components/LangSelect'
export default {
  name: "HelloWorld",
  components: { HOME,WORK,ABOUT },
  props: {
    msg: String,
  },
  data() {
    return {
      baseUrl: 'https://www.quantumspacestudio.com',
      waterfallList:[],
      componentVisiled:true,
      componentId:'HOME',
      imgs:null,
      array: [],
      // windowWidth: document.body.clientWidth,
      ImgWidth: "",
      ImgCol: 5,
      ImgRight: 10,
      ImgBottom: 10,
      deviationHeight: [],
      isNight: false,
      //isNight:new Date().getHours()>=18 || new Date().getHours()<8,
      imgList: [],
      screenWidth: document.body.clientWidth,
      footerIcons: [],
      iconUpdate: false
    };
  },
  computed:{
    btns(){
      return [
        {value:'HOME',lable:this.$t('header.HOME')},
        {value:'WORK',lable:this.$t('header.WORK')},
        {value:'ABOUT',lable:this.$t('header.ABOUT')}
        // ['HOME','WORK','ABOUT']
      ]
    },
    message(){
      return this.$t('header.HOME')
    }
  },
  watch: {
    componentId(){
      this.componentVisiled = false
      setTimeout(() =>{
        this.componentVisiled = true
        // this.$nextTick(()=>{
        //   console.log(this.$refs['component'] )
        // })
      }, 50);
        // this.$refs['component'].onload = function(e){
        //   console.log(this,e.height)
        // }

    },
    // screenWidth(val) {
    //   // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    //   if (!this.timer) {
    //     // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
    //     this.screenWidth = val;
    //     this.timer = true;
    //     let that = this;
    //     setTimeout(function () {
    //       that.calculationWidth(); //重新计算图片宽度
    //       that.timer = false;
    //     }, 400);
    //   }
    // },
  },

  methods: {
    handleBtn(btn){
      if(this.componentId!=btn){
        this.componentId = btn
      }
    },
    btnStyle(btn){
      let  obj = {}
      if(this.componentId==btn){
        obj.color = '#000'
        // obj.borderBottom='2px solid red'
        obj.fontWeight = 900;
      }else{
        obj.color = '#666'
      }
      obj.borderRadius = 0
      obj.padding='5px 0'
      return obj
    },
    chunkData(option) {
      return {
        size: option.fileSize, // 总文件大小
        chunks: option.chunkTotal, // 所有切片数量
        chunk: option.chunkIndex, // 当前切片下标
        md5: option.chunkHash, // 单个切片hash
        filename: option.fileName, // 文件名
        fileHash: option.fileHash, // 整个文件hash
      };
    },
    handleSuccess(response, file, fileList) {
      //文件上传成功
      console.log(response, file, fileList);
    },
    // getWaterfall() {
    //   let columns = this.windowWidth <= 408 ? 1 : 5; //定义布局的列数为2
    //   let item = this.$refs.waterfallItem; //获取每个子元素的DOM
    //   //console.log("item", item);
    //   for (let i = 0; i < item.length; i++) {
    //     //遍历整个子元素的DOM集合
    //     item[i].style.width = this.windowWidth / columns + "px";
    //     //console.log("width",this.windowWidth/columns+'px')
    //     //console.log("item[i].style.width",item[i].style.width)
    //     if (i < columns) {
    //       //小于columns的子元素作为第一行
    //       this.$set(item[i].style, "left", item[0].clientWidth * i + "px");
    //       item[i].style.top = 10 + "px";
    //       //item[i].style.left = item[0].clientWidth * i + "px";
    //       //console.log("style", item[0].clientWidth * i + "px")
    //       this.array.push(item[i].clientHeight); //遍历结束时，数组this.array保存的是第一行子元素的元素高度
    //       //console.log("this.array",this.array)
    //     } else {
    //       //大于等于columns的子元素将作其他行
    //       let minHeight = Math.min(...this.array); //  找到第一列的最小高度
    //       let index = this.array.findIndex((item) => item === minHeight); // 找到最小高度的索引
    //       //设置当前子元素项的位置
    //       item[i].style.top = this.array[index] + 25 + "px";
    //       //console.log(item[index])
    //       if (item[index]) item[i].style.left = item[index].offsetLeft + "px";
    //       //重新定义数组最小项的高度 进行累加
    //       this.array[index] += item[i].clientHeight;
    //       //console.log("this.array[index]",this.array[index])
    //     }
    //   }
    // },
    // //计算每个图片的宽度
    // calculationWidth() {
    //   this.ImgWidth =
    //     (this.screenWidth - this.ImgRight * this.ImgCol) / this.ImgCol;
    //   //初始化偏移高度数组
    //   this.deviationHeight = new Array(this.ImgCol);
    //   for (let i = 0; i < this.deviationHeight.length; i++) {
    //     this.deviationHeight[i] = 0;
    //   }
    //   this.imgPreloading();
    // },
    // //图片预加载
    // imgPreloading() {
    //   this.waterfallList = [];
    //   for (let i = 0; i < this.imgList.length; i++) {
    //     let aImg = new Image();
    //     aImg.src = this.imgList[i].url;
    //     aImg.onload = aImg.onerror = () => {
    //       let imgData = {};
    //       imgData.height = (this.ImgWidth / aImg.width) * aImg.height; //按比例计算图片高度
    //       imgData.src = this.imgList[i];
    //       this.waterfallList.push(imgData);
    //       this.rankImg(imgData); //渲染页面
    //     };
    //   }
    // },
    // //瀑布流布局
    // rankImg(imgData) {
    //   let { ImgWidth, ImgRight, ImgBottom, deviationHeight } = this;
    //   let minIndex = this.filterMin(); //获得高度最小的一列的下标
    //   imgData.top = deviationHeight[minIndex]; //插入图片的top值
    //   imgData.left = minIndex * (ImgRight + ImgWidth); //插入图片的left值
    //   console.log(minIndex)
    //   console.log('deviationHeight',deviationHeight)
    //   console.log(ImgRight)
    //   console.log(ImgWidth)
    //   console.log(imgData.left)
    //   deviationHeight[minIndex] += imgData.height + ImgBottom; //更新当前列的高度
    // },
    // //找到最短的列并返回下标
    // filterMin() {
    //   const min = Math.min.apply(null, this.deviationHeight-0);
    //   console.log('min',min)
    //   return this.deviationHeight.indexOf(min);
    // },
    getIconUrl(value) {
        return value.substring(value.indexOf('href="')+6,value.indexOf('" rel'));
    },
    getTimeZone (date) {
      let opts = {
          timeZone: 'America/New_York',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hourCycle: 'h23'
      }
      return Intl.DateTimeFormat('en-US',opts).format(date)
    }
  },
  mounted() {
      this.$axios.post(this.baseUrl + '/noauth/list', {websiteTypes: ['ICON']}).then((response) => {
        const footerIcons = response?.data?.data?.records;
        this.footerIcons = (footerIcons || []).map(_ => {
          _.imgSrc = this.baseUrl + _.fullPathName + '/' +  _.locationName;
          return _
        });
        this.iconUpdate = !this.iconUpdate
      }).catch(function (error) {
        console.log(error);
      });
  },
  created() {

    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025);
          color: #000;
        }
      }
    }
.footer{
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  &-left{
    display: inline-block;
    //margin-right: 50px;
    margin-top:12px
  }
  &-right{
    display: inline-block;
    margin-left: 35px;
  }
}
.el-header,
.el-main {
  padding: 0;
}
.nav-top{
  position: fixed;
  width: 100%;
  z-index: 2000;
  background: rgba(255,255,255,1);
  top: 0;
  align-items:center;
  &-img{
    transition: background 0.8s ease-in 0.3s, color 0.6s ease-out 0.3s;
    float: left;
  }
  img{
    height: 100%;
    width: 150px;
    margin: 10px 0 8px 20px;
  }
  &-right{
    text-align: right;
    padding-right: 20px;
    float: right;
  }
}
.nav-btn {
  display: flex;
  margin-top: 18px;
}
.middle-list{
  position: absolute;
  top: 48%;
  left: 15%;
  color: #fff;
  z-index: 1000;
  button{
    color: #fff;
    font-size: 26px;
  text-shadow: 2px 2px 0px #9c9c9c;
  }
}
.hello{
  overflow: hidden;
}
.bottom-icon {
  position: fixed;
  right: 0px;
  bottom: 0px;
  img {
    width: 80px;
    height: 80px;
    transform: rotateX(180deg) translateY(-5px);
  }
}
.nav-menu {
  display: none;
}
.nav-menu-btn {
  display: block;
}
@media screen and (max-width: 992px) {
  .footer {
    display: block;
  }
  .footer-left,
  .footer-right {
    width: 50%;
    margin: auto;
  }
  .nav-top {
    height: auto;
  }
  .nav-top img {
    margin: 10px 0 8px 10px;
  }
  .nav-top-right {
    display: flex;
    align-items: center;
    height: 60px;
    padding-right: 10px;
  }
  .el-button--text {
    flex: 1;
  }
  .nav-menu {
    display: block;
  }
  .nav-menu-btn {
    display: none;
  }
  .el-dropdown {
    font-size: 24px;
    margin-top: 0px
  }
}
</style>
