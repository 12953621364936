<template>
  <div style="position: relative;" class="home">
    <div v-if="/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(imgs)" ref="homeCarousel" :style="{backgroundImage: 'url(' + (imgs) + ')'}" class="home-carousel">
    </div>
    <video
      v-else-if="/(http(s)?:\/\/)?\w+(\.\w+)+\/(\w+\/)*(.*?\.)+(swf|avi|flv|mpg|mov|wav|3gp|mkv|rmvb|mp3|mp4)/.test(imgs)"
      :src="imgs"
      muted
      autoplay
      class="home-video">
    </video>
    <div v-html="homeContent" class="home-content"></div>
  </div>
</template>
  <script>
  //import YlUpload from "./upload";

  export default {
    name: "HelloWorld",
    //components: { YlUpload },
    props: {
      msg: String,
    },
    data() {
      return {
        waterfallList:[],
        baseUrl:'https://www.quantumspacestudio.com',
        array: [],
        // bg: {backgroundImage: "url(" + (this.imgs) + ")"},
        defaultBG:'../assets/img/1.jpg',
        imgs:null,
        // windowWidth: document.body.clientWidth,
        ImgWidth: "",
        ImgCol: 5,
        ImgRight: 10,
        ImgBottom: 10,
        deviationHeight: [],
        imgList: [],
        screenWidth: document.body.clientWidth,
        homeContent: ''
      };
    },
    watch: {

    },
    methods: {
      chunkData(option) {
        return {
          size: option.fileSize, // 总文件大小
          chunks: option.chunkTotal, // 所有切片数量
          chunk: option.chunkIndex, // 当前切片下标
          md5: option.chunkHash, // 单个切片hash
          filename: option.fileName, // 文件名
          fileHash: option.fileHash, // 整个文件hash
        };
      },
      handleSuccess(response, file, fileList) {
        //文件上传成功
        console.log(response, file, fileList);
      },

    },
    mounted() {

    },
    created() {
      this.$nextTick(()=>{
        this.$axios.post('https://www.quantumspacestudio.com/noauth/list', {
          websiteTypes:['HOME', 'HOME_COUNT']
        }).then( (res) =>{
            let records = res.data?.data?.records;
            records.forEach(_ => {
              if (_.websiteType === 'HOME') {
                this.imgs = `${this.baseUrl}${_.fullPathName}/${_.locationName}`
              } else {
                this.homeContent = _.websiteContentEn;
              }
            })
        }).catch(function (error) {
            console.log(error);
        });
      })

    },
  };
  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .home-carousel{
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;  /*关键*/
    background-position: center;
  }
  .home-video {
    width: 100vw;
  }
  .middle-list{
    position: absolute;
    top: 50%;
    left: 15%;
    color: #fff;
    font-size: 20px;
  }
  .home-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
  }
  .home {
    height: calc(100vh - 60px);
    overflow: hidden !important;
  }
</style>
