<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
  @font-face{
    font-family: SourceHanSansCN-Medium;
    src: url('../public/SourceHanSansCN-Regular.otf');
    font-weight: normal;
    font-style: normal;
  }
  #app {
    font-family: SourceHanSansCN-Medium;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-weight: 900 !important;
  }
  body{
    margin: 0 !important
  }
  .el-button {
    font-family: SourceHanSansCN-Medium;
    font-weight: 900 !important;
  }
  .el-main {
    overflow: unset !important;
  }
</style>
