import zhLocale from 'element-ui/lib/locale/lang/zh-CN'   //引入element语言包
// zn.js
export default {
      bottom:{
        text:'由量子空间工作室有限公司提供动力'
    },
      dashboard: {
        btn: {
            play: "播放",
            pause: "暂停",
            fullscreen: "全屏",
            exitFullscreen: "退出全屏",
            mute: "静音",
            unmute: "取消静音",
            back: "后退",
            pip: "画中画"
        },
        settings: {
            autoplay: "自动播放",
            loop: "循环",
            speed: "速度",
            resolution: "解释度"
        }
    },
    layers: {
        error: {
            2404: "找不到视频源",
            2502: "媒体网络错误",
            2503: "视频无法解码",
            2504: "视频无法播放!",
            title: "(O_O)?  错误!"
        },
        loading: {
            msg: "加载中 ..."
        }
    },
    main:{
      message:"消息",
      display:"展示"
    },
    header:{
        HOME:'首页',
        WORK:'工作',
        ABOUT:'关于',
    },
    workBtn:{
      ALL:'全部',
      RESIDENTIAL:'住宅',
      CULTURE:'文化',
      COMMERCIAL:'商业',
      ANIMATION:'动画 ',
      VIRTUAL:'虚拟现实',
      LOADMORE:'加载更多',
      NOMORE:'没有更多'
  },
  about:{
    title:'多么好的软件，帮助你完成更多的工作!',
    aboutUs:'关于我们',
    intro:`创造力就是把事物联系起来。当你问有创造力的人他们是如何做某件事的，他们会感到有点内疚，因为他们并没有真正做这件事，他们只是看到了一些东西。我们想要做的是将你的想法与我们“连接”起来，产生一些奇妙的东西。量子空间工作室成立于2015年10月，致力于提供最好的通信和可视化服务。我们的大多数美术人员都在这个行业工作多年，在渲染和动画方面有丰富的经验。`,
    ourClients:'我们的客户',
    contactUs:'联系我们'
  },
    ...zhLocale
  }