<template>
  <div
    class="home-carousel"
    :style="{
      background: isNight ? '#000' : '#fff',
      color: isNight ? '#fff' : '#000',
    }"
  >
    <div v-if="bgimgs.length > 0">
      <div v-if="/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(bgimgs)" class="img-box video-box" style="filter:' blur(10px)'; backdrop-filter: 'blur(12px)';" :style="{ background: 'url(' + bgimgs + ')'}">
      </div>
      <video
        v-else
        :src="bgimgs"
        muted
        autoplay
        class="video-box">
      </video>
      <el-carousel
        :interval="5000"
        class='custom-carousel'
        @change="carouselChange"
        trigger="click"
        height="600px"
        v-if="imgArr.length > 0"
        :arrow="imgArr.length > 1 ? 'hover' : 'never'"
      >
        <el-carousel-item v-for="(item, index) in imgArr" :key="index">
            <div class="carousel-main">
              <img
                :src="item"
                style="object-fit: contain;"
                alt=""
              />
            </div>
          </el-carousel-item>
      </el-carousel>
    </div>
    <div class="about-box">
      <!-- <div class="about-box-header"> -->
        <!-- <h1>
          <span>‘‘</span> {{$t('about.title')}}
          !<span>’’</span>
        </h1> -->
        <!-- <h1></h1> -->
      <!-- </div> -->
      <div class="about-box-main" v-for="(item, index) in items" :key="index">
        <div class="about-box-main-right">
            <p v-html="item.websiteTitleEn"/>
            <p v-html="unescapeHTML(item.websiteContentEn)"/>
          </div>
         <div class="about-x"></div>
      </div>
    </div>
    <el-backtop :bottom="10" :right="10">
        <i class="el-icon-arrow-up" style="color:rgb(102, 102, 102)"></i>
    </el-backtop>
  </div>
</template>

  <script>
//import YlUpload from "./upload";

export default {
  name: "HelloWorld",
  //components: { YlUpload },
  props: {
    msg: String,
  },
  data() {
    return {
      baseUrl: "https://www.quantumspacestudio.com",
      bgimgs: '',
      waterfallList: [],
      imgArr: [
      ],
      Customers: [],
      carouselIndex: 0,
      array: [],
      // windowWidth: document.body.clientWidth,
      ImgWidth: "",
      ImgCol: 5,
      ImgRight: 10,
      ImgBottom: 10,
      deviationHeight: [],
      imgList: [],
      screenWidth: document.body.clientWidth,
      isNight: false,
      // isNight: new Date().getHours() >= 18 || new Date().getHours() < 8,
      contents:[],
      items: []
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.calculationWidth(); //重新计算图片宽度
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    carouselChange(newIndex) {
      this.carouselIndex = newIndex;
      //this.carouselVideo.url = this.carouselList[newIndex]
      // console.log(newIndex,oldIndex)
    },
    chunkData(option) {
      return {
        size: option.fileSize, // 总文件大小
        chunks: option.chunkTotal, // 所有切片数量
        chunk: option.chunkIndex, // 当前切片下标
        md5: option.chunkHash, // 单个切片hash
        filename: option.fileName, // 文件名
        fileHash: option.fileHash, // 整个文件hash
      };
    },
    unescapeHTML(a){
        a = "" + a;
        return a.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
    },


    handleSuccess(response, file, fileList) {
      //文件上传成功
      console.log(response, file, fileList);
    },

  },
  mounted() {
    ///this.calculationWidth();
   // const that = this;
    //挂载window的resize事件
    // window.onresize = () => {
    //   return (() => {
    //     window.screenWidth = document.body.clientWidth;
    //     that.screenWidth = window.screenWidth;
    //   })();
    // };
  },
  created() {
    this.$axios
      .post(this.baseUrl + "/noauth/list", {websiteTypes: ['ABOUT','ABOUTDISPLAY','ABOUTCONTENT'], page: 1, size: 100 })
      .then((res) => {
        let data = res.data.data.records;
        // //console.log(scoringValue)
        data.map((x) => {
          //let scoringValue = x.scoringValue ? data.scoringValue + "/" : "";
          let url = `${this.baseUrl}${x.fullPathName}/`;
          if (x.websiteType == "ABOUT") {
            this.bgimgs = url+ x.locationName;
          }
          if (x.websiteType == "ABOUTDISPLAY") {
            this.imgArr.push(url + x.locationName);
          }
          if(x.websiteType == "ABOUTCONTENT"){
            x.thumbPathNameUrl = url + x.thumbPathName
            x.locationNameUrl = url + x.locationName
            this.contents.push(x.locationNameUrl);
            this.items.push(x);
          }
          console.log(this.bgimgs)
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .about-box-main {
    height: 400px;
    padding-top: 20px;
    &-left {
      display: inline-block;
      width: 65%;
      height: 100%;
      background-size: cover;
      width: calc(65% - 40px);
      margin: 20px;
      img {
        width: 100%;
      }
      text-align: center;
    }
    &-right {
      display: inline-block;
      width: calc(60% - 20px);
      height: 85%;
      vertical-align: top;
      padding: 50px 10px 0 10px;
      text-align: center;
      ul {
        list-style: none;
      }
      li {
        float: left;
        width: 33%;
      }

    }
    .about-x{
      height:1px;
      width:calc(80% - 20px);
      background:black;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 992px) {
  .about-box-main {
    &-left {
      display: inline-block;
      width: 100%;
      height: 300px;
      background-size: cover !important;
      img {
        width: 100%;
      }
    }
    .about-x{
      height:1px;
      width:calc(75% - 20px);
      background:black;
      margin: 0 auto;
    }
    &:last-child{
        .about-x{
            display:none;
        }
    }

    text-align: center;
    padding-bottom:10px;
  }

  h1 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }
  .ql-syntax div {
      display: block !important;
  }
  .video-box {
    height: 300px;
    object-fit: cover;
  }
  /deep/.ql-syntax {
    div {
      display: block !important;
      width: 100% !important;
      text-align: center !important;
    }
  }
}
.home-carousel /deep/ ul.el-carousel__indicators--vertical {
  display: none;
}

.about-box {
  &-header {
    font-size: 40px;
    padding: 50px 50px;
    span {
      margin: 0 20px;
    }
  }
}
.carousel-main {
  border-radius: 4px;
  height: 100%;
}
.img-box {
  width: 100vw;
  background-size: cover !important;
  background-color: #cccccc;
  background-repeat: no-repeat !important;
}
.video-box {
  object-fit: cover;
  height: 100vh;
}
.custom-carousel {
  width: 100vw;
}
</style>
