import Vue from "vue";
import VueI18n from 'vue-i18n'
import zn from "./lang/zn"
import en from "./lang/en"
Vue.use(VueI18n);   // 全局注册国际化包
 
// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'en',   // 初始化中文
  messages: {
    "zn":zn,
    "en":en
  },
  warnHtmlInMessage:true
}); 
 
export default i18n
