<template>
  <div>
    <div
      class="img-box video-box"
      v-if="/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(bgimgs)"
      :style="{ backgroundImage: 'url(' + bgimgs + ')' }"
    >
    </div>
    <video
      v-else
      :src="bgimgs"
      muted
      :autoplay="isMobile ? false : true"
      class="video-box"/>
    <el-carousel
      v-if="carouselList.length > 0"
      :interval="100000"
      @change="carouselChange"
      trigger="click"
      height="650px"
      style="margin-top: 100px"
      class='carousel-work'
      :arrow="carouselList.length > 1 ? 'hover' : 'never'"
    >
      <el-carousel-item
        v-for="(item, index) in carouselList"
        :key="index"
      >
        <div class="carousel-main">
          <div
            class="carousel-main-left"
            :style="{ width: screenWidth > 1100 ? '400px' : '200px' }"
            v-show="screenWidth > 800"
          >
            <h1>SCIENCE</h1>
            <h1>AND</h1>
            <h1>DESIGN</h1>
          </div>
          <div
            class="carousel-main-right"
            :style="{ paddingRight: screenWidth > 1000 ? '60px' : 0 }"
          >
            <vue-core-video-player
              v-if="carouselIndex == index"
              :src="item"
              controls="auto"
              :loop="false"
              preload="nona"
            />
            <!-- <vue-mini-player v-if="carouselIndex == index" :style="{height:'500px',width:'100%',objectFit:'fill'}" ref="vueMiniPlayer" :video="{...carouselVideo,...item}" :mutex="true" @fullscreen="handleFullscreen" /> -->
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="filter-list" v-show="!dialogTableVisible && !vrVisiled">
      <el-dropdown class='nav-menu' @command="handleCommand" trigger='click'>
        <span class="el-dropdown-link">
          <i class="el-icon-s-unfold"></i>
        </span>
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item v-for="(item, index) in [
            'ALL',
            'COMMERCIAL',
            'CULTURE',
            'RESIDENTIAL',
            'ANIMATION',
            'VIRTUAL',
          ]" :key="index" :command="item">{{$t('workBtn.' + item)}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-tabs
        v-model="activeName"
        class="filter-list-tabs"
        :class="isNight ? 'night' : 'daytime'"
        :before-leave="beforeLeave"
        @tab-click="handleClick"
        stretch
      >
        <el-tab-pane
          v-for="(item, index) in [
            'ALL',
            'COMMERCIAL',
            'CULTURE',
            'RESIDENTIAL',
            'ANIMATION',
            'VIRTUAL',
          ]"
          :style="mainStyle"
          :key="index"
          :label="$t('workBtn.' + item)"
          :name="item"
        />
      </el-tabs>
    </div>
    <transition-group
      ref="waterfallList"
      class="v-waterfall-content"
      name="list"
      tag="div"
      id="v-waterfall"
      :style="{
        height: boxHeight + 'px',
        background: isNight ? '#000' : '#fff',
        minHeight:'200px',
        color: isNight ? '#fff' : '#000',
        position: 'relative'
      }"
    >
      <div
        v-for="(img, index) in waterfallList"
        :key="index"
        class="v-waterfall-item"
        :style="{
          top: img.top + 'px',
          left: img.left + 'px',
          width: waterfallImgWidth + 'px',
          height: img.height,
          transition: 'all .3s linear',
        }"
      >
        <!-- <img
          v-if="!img.isImg"
          class="v-waterfall-item-img"
          :src="img.src"
          alt=""
          @click="playVideo(img, index,img.isImg)"
        /> -->
        <el-image
          @click="playVideo(img, index, img.isImg)"
          class="v-waterfall-item-img"
          v-if="img.src"
          :src="img.src"
          fit="fit"
        >
          <div slot="placeholder" class="image-slot">
            {{ $t("layers.loading.msg") }}
          </div>
        </el-image><!-- 
        <el-button
          v-if="!img.isImg"
          class="play-video"
          icon="el-icon-video-play"
          type="text"
          @click="playVideo(img, index, img.isImg)"
        /> -->
        <!-- <div class="v-waterfall-item-title">
          <p style="font-size: small; margin: 0;text-align: left;">
            <b>{{ lang == "zn" ? img.websiteTitle : img.websiteTitleEn }}</b>
          </p>
          <p style="font-size: x-small; margin: 0; padding-bottom: 6px;text-align: left;" v-html="lang == 'zn' ? img.websiteContent : img.websiteContentEn">
          </p>
        </div> -->
      </div>
    </transition-group>
    <div
      v-loading="btnDisabled"
      :style="{
        background: isNight ? '#000' : '#fff',
        color: isNight ? '#fff' : '#000',
        paddingTop: '100px',
      }"
    >

    </div>
    <div
      v-if="dialogTableVisible"
      style="z-index: 2008;background: #fff;align-items: center;display: flex;justify-content: center;"
      fullscreen
      class="img-background el-image-viewer__wrapper"
      @click="closeBig"
    >
      <!-- <div
        class="el-image-viewer__mask"
        style=" opacity: 1; background: #fff;filter: blur(10px); backdrop-filter: blur(12px); padding: 30px;"
        @click="closeBig"
      ></div> -->

      <div style="margin: 40px;">
        <span v-if="currentIdndex" class="el-image-viewer__btn el-image-viewer__prev" @click.stop ="backImg">
          <i class="el-icon-arrow-left" />
        </span>
        <span
          v-if="currentIdndex < waterfallList.length - 1"
          class="el-image-viewer__btn el-image-viewer__next"
          @click.stop="nextImg"
        >
          <i class="el-icon-arrow-right" />
        </span>
        <div style="display: flex; justify-content: center;" @click="closeBig">
          <el-image
            v-if="waterfallList[currentIdndex].isImg && waterfallList[currentIdndex].bigSrc.indexOf('.mp4')<0"
            :src="`data:image/png;base64,${base64}`"
            fit="contain"
            lazy
            v-loading="bigImgLoading"
            class='modal-img'
            @click.stop="imgClick($event)"
            :style="{objectFit: 'contain',
              width: isMobile ? '100vw' : ratio ? 'calc(100vw - 80px)' : 'auto',
              maxWidth: isMobile ? '100vw' : 'calc(100vw - 80px)',
              height: isMobile ? 'auto' : ratio ? 'auto' : 'calc(100vh - 80px)',
              maxHeight: isMobile ? 'auto' : 'calc(100vh - 80px)'}"
          >
          </el-image>
          <video-player
            v-else
                :style="{objectFit: 'contain',
                  width: isMobile ? '100vw' : 'calc(100vw)',
                  maxWidth: isMobile ? '100vw' : '1920px'}"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ended="onPlayerEnded($event)"
                @waiting="onPlayerWaiting($event)"
                @playing="onPlayerPlaying($event)"
                @loadeddata="onPlayerLoadeddata($event)"
                @timeupdate="onPlayerTimeupdate($event)"
                @canplay="onPlayerCanplay($event)"
                @canplaythrough="onPlayerCanplaythrough($event)"
                @statechanged="playerStateChanged($event)"
                @ready="playerReadied">
              </video-player>
          <div class="v-waterfall-item-title">
            <p style="margin-right: 30px;" v-html="title"/>
            <p v-html="subTitle"/>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :bottom="10" :right="10">
        <i class="el-icon-arrow-up" style="color:rgb(102, 102, 102)"></i>
    </el-backtop>
    <div v-show="vrVisiled" style="background-color: #fff;z-index: 10000;" class="img-background el-image-viewer__wrapper" @click="closeBig">
        <div  :style="{objectFit: 'contain',
              width: isMobile ? '100vw' : ratio ? 'calc(100vw - 150px)' : 'auto',
              maxWidth: isMobile ? '100vw' : 'calc(100vw - 150px)',
              height: isMobile ?'calc(100vh - 140px)': 'calc(100vh - 40px)' ,
              maxHeight: isMobile ? 'calc(100vh - 140px)': 'calc(100vh - 40px)',
              overflow: 'hidden',
              margin:isMobile ? '70px auto': '40px auto'
              }">
        <div
          class="el-image-viewer__mask"
          style=" opacity: 1; background: none;filter: blur(10px); backdrop-filter: blur(12px); "
          @click.stop="closeBig"
        />
        <span v-if="currentIdndex" style="z-index: 10001;" class="el-image-viewer__btn el-image-viewer__prev" @click.stop ="backImg">
          <i class="el-icon-arrow-left" />
        </span>
        <span
          v-if="currentIdndex < waterfallList.length - 1" style="z-index: 10001;"
          class="el-image-viewer__btn el-image-viewer__next"
          @click.stop="nextImg"
        >
          <i class="el-icon-arrow-right" />
        </span>
        <!-- <span class="el-image-viewer__btn el-image-viewer__close" v-show="isMobile" @click="closeBig">
          <i class="el-icon-close" />
        </span> -->
        <!-- <span style=" z-index: 3009; top: 30px;right: 10%; " class="el-image-viewer__btn el-image-viewer__close" @click="closeBig">
          <i class="el-icon-close" />
        </span> -->
        <div style="display: flex;width: inherit; justify-content: center">
          <div id="container"/>
        </div>
        <div class="v-waterfall-item-title">
          <p style='margin-right: 30px;' v-html="title" />
          <p v-html="subTitle" />
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
import * as THREE from "three";
var camera;
var renderer;
var scene;
export default {
  name: "HelloWorld",
  computed: {
      player() {
        return this.$refs.videoPlayer.player;
      },
    },
  components: {
    videoPlayer
  },
  props: {
    msg: String,
  },
  data() {
    return {
       url:"",
      vrVisiled:false,
      btnDisabled: false,
      lang: localStorage.getItem("language") || "en",
      baseUrl: "https://www.quantumspacestudio.com",
      boxHeight: 0,
      activeName: "ALL",
      thisType:"",
      vodeoUrl: "",
      isNight: false,
      //isNight: new Date().getHours() >= 18 || new Date().getHours() < 8,
      poster: "",
      video: {
        // url: 'https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4',
        cover: "https://i.loli.net/2019/06/06/5cf8c5d9c57b510947.png",
        muted: false,
        loop: false,
        preload: "auto",
        poster: "",
        volume: 1,
        crossorigin: "anonymous",
        autoplay: false,
      },
      carouselIndex: 0,
      carouselVideo: {
        url: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
        // cover: 'https://i.loli.net/2019/06/06/5cf8c5d9c57b510947.png',
        muted: false,
        loop: false,
        preload: "auto",
        poster: "",
        volume: 1,
        crossorigin: "anonymous",
        autoplay: false,
      },
      carouselList: [],
      dialogTableVisible: false,
      waterfallList: [],
      imgArr: [],
      // waterfallImgWidth: 100,
      waterfallImgWidth: 290, // 每个盒子的宽度
      waterfallImgCol: 5, // 瀑布流的列数
      // waterfallImgCol: 3,// 瀑布流的列数
      waterfallImgRight: 10, // 每个盒子的右padding
      waterfallImgBottom: 10, // 每个盒子的下padding
      waterfallDeviationHeight: [],
      imgList: [],
      screenWidth: document.body.clientWidth,
      waterfallImgOffFleft: 0,
      timer: false,
      total: 0,
      page: 1,
      size: 10,
      currentIdndex: 0,
      currentType: null,
      imgLoadNumber: 10,
      playerOptions : {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, // 如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '15:6', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [],
          poster: "", // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 时间分割线
            durationDisplay: true, // 总时间
            remainingTimeDisplay: true, // 剩余播放时间
            progressControl: true, // 进度条
            fullscreenToggle: true  // 全屏按钮
          }
        },
      mainStyle: {
        background: this.isNight ? "#000" : "#fff",
        color: this.isNight ? "#fff" : "#000",
      },
      bgimgs: null,
      works: [
        "RESIDENTIAL",
        "COMMERCIAL",
        "CULTURE",
        "VIRTUAL＿TOUR＿360",
      ],
      bigImg: null,
      windowHeight: window.innerHeight,
      title: '',
      subTitle: '',
      ratio: true,
      isMobile: document.body.clientWidth <= 992,
      base64:'',
      bigImgLoading:false
    };
  },
  watch: {
    async screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        await this.changeImgList();
      }
    },
    activeName(n, o) {
      if (n != o) {
        this.page = 1;
        this.waterfallList.length = 0;
        this.waterfallList = [];
        this.waterfallDeviationHeight = [];
        this.imgList.length = 0;
        this.changeList({ label: this.activeName });
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    /*
      其实多数是列出的一些回调函数而已，不需要使用的话忽略掉即可
      */
      // 播放回调
      onPlayerPlay(player) {
        console.log("player play!", player);
      },
      // 暂停回调
      onPlayerPause(player) {
        console.log("player pause!", player);
      },
      // 视频播完回调
      onPlayerEnded(player) {
        console.log("player end!", player);
      },
      // DOM元素上的readyState更改导致播放停止
      onPlayerWaiting(player) {
        console.log("Player Waiting",player);
      },
      // 已开始播放回调
      onPlayerPlaying(player) {
        console.log("Player Playing",player);
      },
      // 当播放器在当前播放位置下载数据时触发
      onPlayerLoadeddata(player) {
        console.log("Player Loadeddata",player);
      },
      // 当前播放位置发生变化时触发。
      onPlayerTimeupdate(player) {
        console.log("Player Timeupdate",player);
      },
      // 媒体的readyState为HAVE_FUTURE_DATA或更高
      onPlayerCanplay(player) {
        console.log('player Canplay!', player)
      },
      // 媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
      onPlayerCanplaythrough(player) {
        console.log('player Canplaythrough!', player)
      },
      //播放状态改变回调
      playerStateChanged(playerCurrentState) {
        console.log("player current update state", playerCurrentState);
      },
      //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
      playerReadied(player) {
        console.log("example player 1 readied", player);
      },
    async changeList(val) {
      if (this.btnDisabled) {
        return;
      }
      if (typeof val === 'string') {
        val = {label: val};
      }
      let websiteTypes = null;
      let { page, size } = this;
      if (val.label == "ALL") {
        websiteTypes = this.works;
      } else {
        if (val.label == "VIRTUAL") {
          websiteTypes = ["VIRTUAL＿TOUR＿360"];
        } else {
          websiteTypes = [val.label];
        }
      }
      this.thisType = websiteTypes;
      // let data = this.queryList(websiteTypes)
      //   console.log(data)
      this.btnDisabled = true;
      await this.$axios
        .post(this.baseUrl + "/noauth/list", {
          websiteTypes: websiteTypes,
          page: page,
          size: size,
        })
        .then(async (res) => {
          let data = res.data.data.records;
          this.total = res.data.data.total;
          this.noMore = this.total - page * this.size < 0;
          if(!this.noMore )this.page++;
          //console.log(scoringValue)
          await data.map(async (x) => {
            //let scoringValue = x.scoringValue ? data.scoringValue + "/" : "";
            let url = `${this.baseUrl}${x.fullPathName}/`;
            x.src = url + x.thumbPathName;
            x.bigSrc = url + x.locationName;
            let type = x.src.split(".")[x.src.split(".").length - 1];
            let isImg = [ "jpeg", "gif", "jpg", "png", "bmp", "pic", "svg", "mp4", "ogg", "webm", ].includes(type);
            if (type == "gif") {
              x.src = x.bigSrc;
            }
            if (type == "mp4") {
              x.src = x.src.replace(".mp4", ".jpg");
              x.isImg = false;
            }
            if (isImg) {
              await this.calculationWidth(x);
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      this.btnDisabled = false;
    },
    beforeLeave() {
      if (this.btnDisabled) {
        return false;
      }
      // console.log(n,o)
      //   this.page = 1;
      //   this.waterfallList.length = 0
      //   this.waterfallDeviationHeight = []
      //   this.imgList.length = 0
      //   this.changeImgList({label:this.activeName})
      //   return true
      // }else{
      //   return false
      // }
    },
    handleClick() {
      //console.log(this.activeName)
      //console.log(val)
      //console.log(websiteTypes)
    },
    handleCommand(val){
        this.page = 1;
        this.waterfallList.length = 0;
        this.waterfallList = [];
        this.waterfallDeviationHeight = [];
        this.imgList.length = 0;
        this.activeName=val;
        this.changeList({ label: val });
        return true;
    },
    queryList(obj) {
      this.$axios
        .post(this.baseUrl + "/noauth/list", { websiteTypes: obj })
        .then((res) => {
          return res.data.data.records;
          //console.log(scoringValue)
        });
    },
    carouselChange(newIndex) {
      this.carouselIndex = newIndex;
      //this.carouselVideo.url = this.carouselList[newIndex]
      // console.log(newIndex,oldIndex)
    },
    changeImgList() {
      setTimeout(async () => {
        this.waterfallDeviationHeight.length = 0;
        this.waterfallList.map(async (x) => {
          await this.calculationWidth(x, true);
        });
        //重新计算图片宽度
        //this.boxHeight+=waterfallDeviationHeight[0]
        // if(this.waterfallList.length!=this.imgArr.length){
        //   this.changeImgList()
        // }
        this.timer = false;
      }, 1000);
    },
    async backImg() {
      if (!this.currentIdndex) return;
      this.bigImgLoading = true;
      this.currentIdndex--;
      const img = this.waterfallList[this.currentIdndex];
      if (!img.base64) {
        this.base64 = await this.getbase64(img.websiteImage);
        img.base64 = this.base64;
      } else {
        this.base64 = img.base64;
      }
      this.getImgSize(img.bigSrc)
      this.title = this.lang == "zn" ? img.websiteTitle : img.websiteTitleEn;
      this.subTitle = this.lang == "zn" ? img.websiteContent : img.websiteContentEn;
      if (!this.waterfallList[this.currentIdndex].isImg) {
        this.video.url = img.src;
      }
      if(img.websiteType=="VIRTUAL＿TOUR＿360"){
        // let loadingInstance = Loading.service({ fullscreen: true ,lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)'});
        this.dialogTableVisible =false
        this.vrVisiled = true
        this.$nextTick(async () => {
         // this.bigImgLoading = true;
          await this.init();
          await this.animate();
          //loadingInstance.close();
         // this.bigImgLoading = false;
        });
      }
      if (img.bigSrc.indexOf(".mp4")>-1) {

        this.playerOptions.poster = "";
            const video = {
              src: img.bigSrc,  // 路径
              type: 'video/mp4'  // 类型
            };
            this.playerOptions.sources = [];
            this.playerOptions.sources.push(video);
      }
      this.bigImgLoading = false;
    },
    async nextImg() {
      if (this.currentIdndex === this.waterfallList.length) return;
      this.bigImgLoading = true;
      this.currentIdndex++;
      const img = this.waterfallList[this.currentIdndex];
      this.title = this.lang == "zn" ? img.websiteTitle : img.websiteTitleEn;
      this.subTitle = this.lang == "zn" ? img.websiteContent : img.websiteContentEn;
      if (!img.base64) {
        this.base64 = await this.getbase64(img.websiteImage);
        img.base64 = this.base64;
      } else {
        this.base64 = img.base64;
      }
      this.getImgSize(img.bigSrc)
      if (!this.waterfallList[this.currentIdndex].isImg) {
        this.video.url = img.src;
      }
      if (img.bigSrc.indexOf(".mp4")>-1) {

          this.playerOptions.poster = "";
              const video = {
                src: img.bigSrc,  // 路径
                type: 'video/mp4'  // 类型
              };
              this.playerOptions.sources = [];
              this.playerOptions.sources.push(video);
        }
      if(img.websiteType=="VIRTUAL＿TOUR＿360"){
        // let loadingInstance = Loading.service({ fullscreen: true ,lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)'});
        this.dialogTableVisible =false
        this.vrVisiled = true
        this.$nextTick(() => {
          this.init();
          this.animate();
          //loadingInstance.close();
        });
      }
      this.bigImgLoading = false;
    },
    closeBig(event) {
      if(event.currentTarget == event.target){
        let root = document.documentElement;
        root.style.overflow = "";
        root.style.borderRight = "";
        //this.bigImg = ""
        this.currentType = "";
        this.currentIdndex = "";
        this.vrVisiled = false
        this.dialogTableVisible = false;
      }
    },
    imgClick(e) {
      e.stopPropagation();

    },
    async playVideo(img, index, isImg) {
      this.dialogTableVisible = true;
      this.bigImgLoading = true;
      let widthBar = 17, root = document.documentElement;
      if (typeof window.innerWidth == "number") {
        widthBar = window.innerWidth - root.clientWidth;
      }
      root.style.overflow = "hidden";
      root.style.borderRight = widthBar + "px solid transparent";
      this.currentType = isImg;
      this.currentIdndex = index;
      this.title = this.lang == "zn" ? img.websiteTitle : img.websiteTitleEn;
      this.subTitle = this.lang == "zn" ? img.websiteContent : img.websiteContentEn;
      if (img.bigSrc.indexOf(".mp4")>-1) {
        //this.video.url = img.bigSrc;
        //this.video.cover = img.src;
        this.playerOptions.poster = "";
            const video = {
              src: img.bigSrc,  // 路径
              type: 'video/mp4'  // 类型
            };
            this.playerOptions.sources = [];
            this.playerOptions.sources.push(video);
      }
      if (!img.base64) {
        this.base64 = await this.getbase64(img.websiteImage);
        img.base64 = this.base64;
      } else {
        this.base64 = img.base64;
      }
      this.getImgSize(img.bigSrc)
      //console.log(1111);
      if(img.websiteType=="VIRTUAL＿TOUR＿360"){
        this.dialogTableVisible =false
        this.vrVisiled = true
        this.$nextTick(() => {
          this.init();
          this.animate();

        });
      }

      this.bigImgLoading = false;
    },
    getbase64(id) {
      return new Promise((resolve, reject) => {
        this.$axios.get('https://www.quantumspacestudio.com/noauth/detail?id='+id).then( (res) => {
          resolve(res?.data?.data);
        }).catch(function (error) {
            console.log(error);
            reject(error);
        });
      })
    },
    getImgSize(url) {
      var img = document.createElement('img');
      img.src = url;
      img.onload = () => {
        const {naturalWidth, naturalHeight} = img;
        const ratio = naturalWidth / naturalHeight;
        this.ratio = ratio > 2;
      }
    },
    // 全景图配置函数---------------
    init() {
      var container = document.getElementById("container");
      // 创建渲染器
      renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      // 设置画布的宽高
      let vrWidth = this.isMobile?document.getElementById("v-waterfall").offsetWidth+20:document.getElementById("v-waterfall").offsetWidth- 150
      let vrHeight = this.isMobile?window.innerHeight-140 : window.innerHeight-80
      renderer.setSize(vrWidth,vrHeight );
      // 判断容器中子元素的长度
      let childs = container.childNodes;
      if (container.childNodes.length > 0) {
        container.removeChild(childs[0]);
        container.appendChild(renderer.domElement);
      } else {
        container.appendChild(renderer.domElement);
      }
      //   container.appendChild(renderer.domElement);
      // 创建场景
      scene = new THREE.Scene();
      // 创建相机
      camera = new THREE.PerspectiveCamera(
        90,
        window.innerWidth / window.innerHeight,
        0.1,
        100
      );
      camera.position.set(0, 0, 0);
      var material = new THREE.MeshBasicMaterial(); //材质
      //将base64格式分割：['data:image/png;base64','XXXX']
      const blob = this.base64ImgtoFile('data:image/png;base64'+','+this.waterfallList[this.currentIdndex].base64)
      const blobUrl = window.URL.createObjectURL(blob);
      var texture = new THREE.TextureLoader().load(blobUrl);
      material.map = texture;
      var skyBox = new THREE.Mesh(
        new THREE.SphereBufferGeometry(100, 100, 100),
        material
      );
      skyBox.geometry.scale(1, 1, -1);
      scene.add(skyBox);
      window.addEventListener("resize", this.onWindowResize, false);
      var bMouseDown = false;
      var x = -1;
      var y = -1;

        // 添加鼠标事件

          container.onmousedown = function (event) {
                                              event.preventDefault(); //取消默认事件
                                              x = event.clientX;
                                              y = event.clientY;
                                              bMouseDown = true;
                                            };

          container.addEventListener("touchstart", function (event) {
                                                           event.preventDefault(); //取消默认事件
                                                         x = event.touches[0]['clientX']
                                                         y = event.touches[0]['clientY'] ;
                                                         bMouseDown = true;
                                                          })
          container.onmouseup = function (event) {
            event.preventDefault();
            bMouseDown = false;
          };
          container.addEventListener("touchmove",function (event){
                                            console.log('touchmove')
                                            event.preventDefault();
                                            if (bMouseDown) {
                                              skyBox.rotation.y += -0.005 * (event.touches[0]['clientX'] - x);
                                              skyBox.rotation.x += -0.005 * (event.touches[0]['clientY'] - y);
                                              if (skyBox.rotation.x > Math.PI / 2) {
                                                skyBox.rotation.x = Math.PI / 2;
                                              }
                                              if (skyBox.rotation.x < -Math.PI / 2) {
                                                skyBox.rotation.x = -Math.PI / 2;
                                              }
                                             x = event.touches[0]['clientX'];
                                             y = event.touches[0]['clientY'] ;
                                            }
                                         });
          container.onmousemove = function (event){
            console.log('onmousemove')
            event.preventDefault();
            if (bMouseDown) {
              skyBox.rotation.y += -0.005 * (event.clientX - x);
              skyBox.rotation.x += -0.005 * (event.clientY - y);
              if (skyBox.rotation.x > Math.PI / 2) {
                skyBox.rotation.x = Math.PI / 2;
              }
              if (skyBox.rotation.x < -Math.PI / 2) {
                skyBox.rotation.x = -Math.PI / 2;
              }
              x = event.clientX;
              y = event.clientY;
            }
         }
          container.onmousewheel = function (event) {
            event.preventDefault();
            if (event.wheelDelta != 0) {
              camera.fov += event.wheelDelta > 0 ? 1 : -1;
              if (camera.fov > 150) {
                camera.fov = 150;
              } else if (camera.fov < 30) {
                camera.fov = 30;
              }
              camera.updateProjectionMatrix();
            }
          };

    },
    //画线的函数
    draw(starX, starY, endX, endY) {
      var container = document.getElementById("container");
      var ctx = container.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(starX, starY);
      ctx.lineTo(endX, endY);
      ctx.closePath();
      ctx.stroke();
    },
    onWindowResize() {
      // 窗口缩放的时候,保证场景也跟着一起缩放
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
    },
    base64ImgtoFile (dataurl, filename = 'file') {
      //将base64格式分割：['data:image/png;base64','XXXX']
      const arr = dataurl.split(',')
      // .*？ 表示匹配任意字符到下一个符合条件的字符 刚好匹配到：
     // image/png
      const mime = arr[0].match(/:(.*?);/)[1]  //image/png
      //[image,png] 获取图片类型后缀
      const suffix = mime.split('/')[1] //png
      const bstr = atob(arr[1])   //atob() 方法用于解码使用 base-64 编码的字符串
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    chunkData(option) {
      return {
        size: option.fileSize, // 总文件大小
        chunks: option.chunkTotal, // 所有切片数量
        chunk: option.chunkIndex, // 当前切片下标
        md5: option.chunkHash, // 单个切片hash
        filename: option.fileName, // 文件名
        fileHash: option.fileHash, // 整个文件hash
      };
    },
    handleSuccess(response, file, fileList) {
      //文件上传成功
      console.log(response, file, fileList);
    },
    //计算每个图片的宽度或者是列数
    calculationWidth(item, isCalc) {
      let domWidth = document.getElementById("v-waterfall").offsetWidth;
      // let domWidth = this.screenWidth;
      this.waterfallImgWidth = 285;
      if (this.isMobile) {
        this.waterfallImgWidth = document.body.clientWidth - 10;
      }
      // 计算当前宽度支持多少列
      this.waterfallImgCol = Math.trunc(
        (domWidth - 20) / (this.waterfallImgWidth + this.waterfallImgRight)
      );
      // 如果超过 2列，设置为2列，重新计算宽度
      if (this.waterfallImgCol > 2) {
        this.waterfallImgCol = 2;
        this.waterfallImgWidth =
          (domWidth - 20 - this.waterfallImgRight * this.waterfallImgCol) /
          this.waterfallImgCol;
          // console.log('超过5列')
      } else if (!this.waterfallImgWidth && this.waterfallImgCol) {
        // 宽度没有，有列数，计算宽度
        this.waterfallImgWidth = (domWidth - this.waterfallImgRight * this.waterfallImgCol) / this.waterfallImgCol;
        // console.log('计算宽度', this.waterfallImgWidth)
      } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
        this.waterfallImgCol = Math.floor(
          domWidth / (this.waterfallImgWidth + this.waterfallImgRight)
        );
        // 计算列数
        // console.log('计算列数', this.waterfallImgCol)

      }
      this.waterfallImgOffFleft = (domWidth - (this.waterfallImgWidth + this.waterfallImgRight) * this.waterfallImgCol) / 2;
      if (this.isMobile) {
        this.waterfallImgOffFleft = 5;
      }
      // this.waterfallImgOffFleft = ((domWidth - 20) % (this.waterfallImgWidth + this.waterfallImgRight)) / 2;
      // console.log(this.waterfallImgOffFleft, 'this.waterfallImgOffFleftthis.waterfallImgOffFleftthis.waterfallImgOffFleftthis.waterfallImgOffFleft')
      //初始化偏移高度数组
      if (!this.waterfallDeviationHeight.length) {
        this.waterfallDeviationHeight = new Array(this.waterfallImgCol);
        for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
            this.waterfallDeviationHeight[i] = 0;
        }
      }
      if (isCalc) {
        this.rankImg(item);
      } else {
        this.loadImg(item);
      }
    },
    //图片预加载
    async loadImg(item) {
      let aImg = new Image();
      let type = item.src.split(".")[item.src.split(".").length - 1];
      let isImg = ["jpeg", "gif", "jpg", "png", "bmp", "pic", "svg"].includes(
        type
      );
      if (isImg) {
        aImg.src = item.src;
      } else {
        let video = this.createVideo(item.src);
        await this.captureImage(video).then((url) => {
          aImg.src = url;
          item.src = url;
        });
      }
      aImg.websiteType = item.websiteType;
      aImg.onload = aImg.onerror = async () => {
        if (this.thisType.includes(aImg.websiteType) ) {
          let imgData = item;
          imgData.height = (this.waterfallImgWidth / aImg.width) * aImg.height;
          imgData.width = this.waterfallImgWidth;
          //imgData.src = aImg.src;
          imgData.isImg = isImg;
          this.waterfallList.push(imgData);
          await this.rankImg(imgData);
         } else {
          console.log("false");
         }
      };
    },
    //瀑布流布局
    async rankImg(imgData) {
      let {
        waterfallImgWidth,
        waterfallImgRight,
        waterfallImgBottom,
        waterfallDeviationHeight,
      } = this;
      let minIndex = this.filterMin();
      imgData.top = waterfallDeviationHeight[minIndex];
      imgData.left =
        minIndex * (waterfallImgRight + waterfallImgWidth) +
        this.waterfallImgOffFleft;
      imgData.height = (this.waterfallImgWidth / imgData.width) * imgData.height;
      imgData.width = this.waterfallImgWidth;
      waterfallDeviationHeight[minIndex] += (imgData.height || 0) + waterfallImgBottom  + 0; // 加了文字的盒子高度，留出文字的地方（这里设置56px）
      this.boxHeight = Math.max.apply(null, this.waterfallDeviationHeight) + 0;
    },
    /**
     * 找到最短的列并返回下标
     * @returns {number} 下标
     */
    filterMin() {
      const min = Math.min.apply(null, this.waterfallDeviationHeight);
      return this.waterfallDeviationHeight.indexOf(min);
    },
    captureImage(videoEle) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const canvas = document.createElement("canvas");
          let imgWidth = videoEle.videoWidth;
          let imgHeight = videoEle.videoHeight;
          videoEle.addEventListener(
            "timeupdate",
            () => {
              canvas
                .getContext("2d")
                .drawImage(videoEle, 0, 0, imgWidth, imgHeight);
              const dataUrl = canvas.toDataURL("image/png");
              videoEle.pause();
              resolve(dataUrl);
            },
            false
          );
        }, 500);
      });
    },
    createVideo(src) {
      const video = document.createElement("video");
      video.setAttribute("height", "500");
      video.setAttribute("width", "280");
      //video.setAttribute("preload", "auto");
      video.setAttribute("src", src);
      video.setAttribute("crossorigin", "anonymous");
      video.setAttribute('playsinline', "false");
      video.setAttribute("x-webkit-airplay", "allow");
      video.setAttribute("autoplay", "false");
      video.setAttribute("crossOrigin", "anonymous");
      video.setAttribute("webkit-playsinline", "false");
      video.setAttribute('style',"display")
      //video.setAttribute("muted", "true");
      return video;
    },
    handleFullscreen() {},
    addImg() {
      this.changeList({ label: this.activeName });
      // for (let i = 0; i < this.imgList.length+this.imgLoadNumber; i++) {
      //this.imgList.push(this.imgArr[Math.round(Math.random() * 8)]);// 图片随机显示
      //this.calculationWidth(this.imgList[i])
      // if(this.imgArr[i]){
      //   this.imgList.push(this.imgArr[i]);
      // }
      // }
    },
    //获取当前滚动条的位置
    getScrollTop() {
        var scrollTop = 0;
        //window.pageYOffset = document.documentElement.scrollTop
        if (document.documentElement && document.documentElement.scrollTop) {
            scrollTop = document.documentElement.scrollTop
        } else if (document.body) {
            scrollTop = document.body.scrollTop
        }
        return scrollTop
    },
    //获取当前可视范围的高度
    getClientHeight() {
        var clientHeight = 0;
        if (document.body.clientHeight && document.documentElement.clientHeight) {
            clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
        } else {
            clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight)
        }
        return clientHeight
    },

    //获取文档完整的高度
    getScrollHeight() {
        return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
    },
    windowScroll() {
    var _this=this;
        setTimeout(function(){
            //获取三个值
            var scrollTop = _this.getScrollTop()
            var clientHeight = _this.getClientHeight()
            var scrollHeight = _this.getScrollHeight()
            //如果满足公式则，确实到底了
            if(scrollHeight - (scrollTop + clientHeight) <= 10){
                //发送异步请求请求数据，同时携带offset并自增offset
                //noMore是自定义变量，如果是最后一批数据则以后都不加载
                if(!_this.noMore) {
                  _this.addImg();
                }
            }
        },100)

    }
  },
  async mounted() {
    const that = this;
    //挂载window的resize事件
    window.onresize = () => {
      return (() => {

        window.screenWidth = document.body.clientWidth;
        this.isMobile  = window.screenWidth <= 992
        // that.screenWidth = window.screenWidth;
        that.screenWidth = document.getElementById("v-waterfall").offsetWidth
      })();
    };

    // 监听键盘事件
    document.onkeydown = function () {
        let key = window.event.keyCode;
        // 返回上一页
        if (key === 37 || key === 38) {
          that.backImg();
        } else if (key === 39 || key === 40) { // 下一页
          that.nextImg()
        }
    }

    window.addEventListener('scroll', this.windowScroll,true) //监听页面滚动
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);//销毁滚动事件
  },
  beforeDestroy() {},
  created() {
    this.$axios
      .post(this.baseUrl + "/noauth/list", {websiteTypes: ["WORK","WORK_CONTENT"], page: 1, size: 100 })
      .then((res) => {
        let data = res.data.data.records;
        data.map((x) => {
          //let scoringValue = x.scoringValue ? data.scoringValue + "/" : "";
          let url = `${this.baseUrl}${x.fullPathName}/`;
          if (x.websiteType == "WORK") {
            this.bgimgs = url + x.locationName;
          }
          if (x.websiteType == "WORK_CONTENT") {
            this.carouselList.push(url + x.thumbPathName);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    this.changeList({ label: this.activeName });
    // for (let i = 0; i < this.imgLoadNumber; i++) {
    //   //this.imgList.push(this.imgArr[Math.round(Math.random() * 8)]);// 图片随机显示
    //   this.imgList.push(this.imgArr[i]);
    // }
  },
};
</script>

<style lang="scss" scoped>
.load-more {
  background: red;
  color: #fff;
  padding: 10px 20px;
  // position: absolute;
  cursor: pointer;
  position: relative;
  top: -40px;
}
.filter-list {
  width: 800px;
  padding-top: 10px;
  margin: auto;
  &-tabs {
    border: 0;
  }
}
.carousel-main {
  display: flex;
  align-items: flex-start;
  justify-items: left;
  height: 100%;
  &-left {
    color: #e2e2e2;
    width: 300px;
    font-size: 30px;
    text-align: left;
    padding: 0 60px 0 40px;
  }
  &-right {
    flex: 1;
    height: 100%;
    // padding-right: 60px;
  }
}
.list-enter-active,
.list-leave-active {
  transition: all 2s;
}
.img-box {
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;
  //background-repeat: no-repeat;
  //background-attachment: fixed;  /*关键*/
  background-position: center;
  width: 100vw;
  height: 74vh;
  // position: absolute;
  padding-top: 80px;
  overflow: hidden !important;
  background-color: #cccccc;
  // top: 0;
  // left: 0;
}
.video-box {
  width: 100%;
  object-fit: cover;
}

.filter-list-tabs {
  display: block;
}

.nav-menu {
  display: none;
}
/deep/.video-js .vjs-big-play-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 36px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 992px) {
  .filter-list-tabs {
    display: none;
  }
  ::v-deep .vjs-fluid {
      padding-top :56.5% !important;
  }
  .nav-menu {
    display: block;
    line-height: 30px;
    text-align: left;
    margin-bottom: 2px;
  }
  .img-box{
    width:100%;
    height:300px;
  }
  .video-box {
    height: 300px;
    object-fit: cover;
  }
  .filter-list {
    padding-top: 0;
    margin-left: 5px;
    width: 40px;
  }
  .el-icon-s-unfold {
    font-size: 18px;
  }
  /deep/.video-js .vjs-big-play-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 36px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (min-width: 992px) {
    ::v-deep .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-1-1{
        width:71% !important;

    }
    ::v-deep .vjs-fluid {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}
.el-image {
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: mymove 0.6s ease-in forwards;
}
@keyframes mymove {
  10% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
#container{
  width: 100%;
  height: 100%;
  z-index: 20;
}
.image-slot {
  height: 50px;
}
.list-enter,
.list-leave-to {
  /* 进入的初始状态和离开的最终状态 */
  opacity: 0;
  transform: translateY(30px);
}
/* .list-list-move {
      transition: all 2s;
} */
.waterfull {
  /* width: calc(100% - 100px); */
  width: 100%;
  /* margin: 0 auto; */
  text-align: center;
  background: #000;
}
.v-waterfall {
  &--content {
    /* 主要 */
    width: 100%;
    /* height: 100vh; */
    position: relative;
    /* 次要：设置滚动条，要求固定高度 */
  }
  &-item {
    /* 主要 */
    // float: left;
    position: absolute;
    &-img {
      width: 100%;
    }
    img {
      /* 主要 */
      /* width: auto;height: auto; */
      width: 100%;
      height: auto;
      cursor: pointer;
      /* 次要 */
    }
    &-title{
       /* 主要 */
      position: absolute;
      bottom: 0;
      transition: all 0.4s linear;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      left: 0;
      height: 40px;
    }
  }
  // &-item:hover &-item-title{
  //     /* 主要 */
  //   opacity: 1;
  //   overflow: hidden;
  // }
}
.play-video {
  position: absolute;
  top: 50%;
  font-size: 50px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
  margin-top: -18px;
}
div.work-dialog {
  min-width: 410px;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  &-img {
    width: 100%;
    height: 100%;
  }
  &-video {
    margin-top: 100px;
  }
}
.work-dialog .el-dialog__header {
  padding: 0;
}
.work-dialog .el-dialog__body {
  padding: 0;
}
.night .el-tabs__item {
  color: #fff;
}
.carousel-work {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}
.el-image-viewer__close {
  top: 10px;
  right: 10px;
  font-size: 20px
}
.el-image-viewer {
  &__next {
    right: 5px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 0;
    color: #000;
  }
  &__prev {
    width: 30px;
    height: 30px;
    left: 5px;
    background: #fff;
    border-radius: 0;
    color: #000;
  }
}

.modal-img {
  height: auto;
  width: auto;
}
.modal-img/deep/.el-image__placeholder {
  background: #fff;
}
/deep/.is-active{
  font-weight: 900;
  color: #000 !important;
}
/deep/.el-tabs__active-bar {
  background-color: transparent;
}
/deep/.image-slot,
/deep/.el-tabs__item {
  font-weight: 900;
  color: #666;
}
/deep/ #tab-VIRTUAL {
padding-right:25px;
}
::v-deep .vjs-poster {
  background-size: cover;
}
::v-deep .video-js{
background-color:#fff !important;
}
</style>

